import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Illu from "../components/illu"
import SectionCTA from "../components/section-cta"
import Img from "gatsby-image"
import slugify from "../utils/slugify"


const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(edge => edge.node)
  const lastPost = posts.shift()

  return (
    <Layout
     /*  messageBody={
        <>
          <p>
            C’est le grand final ! <br />
            Bonnes vacances à tous, on se retrouve à la rentrée avec le plein d’idées!
          </p>
          <br />
          Poney<br />
          <a href='mailto:pauline@poney-illustrations.be'>pauline@poney-illustrations.be</a>
        </>
      } */
    >
      <SEO
        image={lastPost.frontmatter.featuredImage.childImageSharp.resize.src}
      />
      <div class="section">

        <div class="container has-text-centered is-max-desktop">
          <h2 class="title pb-6 is-size-4-mobile">Illustration du jour</h2>
          <Illu post={lastPost} critical />
        </div>
      </div>
      <SectionCTA />
      <div class="section">
        <div class="container has-text-centered">
          <h2 class="title pb-6 is-size-4-mobile">Illustrations précédentes</h2>
          <div className="columns is-multiline is-mobile is-vcentered">
            {posts.map(post =>
              <>
                <div key={post.frontmatter.date} className="column is-3-tablet is-12-mobile is-vcentered py-6">
                  <Link to={"/illu/" + post.frontmatter.date + "/" + slugify(post.frontmatter.title)}>
                    <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    
{
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: {isFuture: {eq: false}}
  ) {
    edges {
      node {
        fileAbsolutePath
        isFuture
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")  
          youtubePlayButton
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1344, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
              resize(width: 900, quality: 90) {
                src
              }
            }
          }
        }
      }
    }
  }
}
  `

export default IndexPage